import { Injectable } from '@angular/core'
import { defaultTo, equals, isNil, of, propEq, uniq } from 'ramda'
import { BehaviorSubject, firstValueFrom, Observable, tap } from 'rxjs'
import { Storage } from '@ionic/storage'
import {
    CreatePaymentMutationService,
    PaymentCreationResultFragment,
    PaymentDetailFragment,
    PaymentQueryService,
    VerifyPaymentMutationService,
    VoucherFragment,
    VouchersByEmailQueryService,
    VoucherUsageInput,
} from '@app-graphql/api-schema'
import { VoucherCodes } from './payment.service.types'
import { catchError, map } from 'rxjs/operators'
import { distinctUntilChangedEquals, shareReplayOne } from '@lib/rxjs/rxjs.lib'
import { AuthService } from '@app/services/auth/auth.service'
import { RequiresInitialization } from '@app/types/framework.types'
import { UnaryFunction } from '@app/types/common.types'

@Injectable({
    providedIn: 'root',
})
export class PaymentService implements RequiresInitialization {

    private readonly voucherCodesStorageKey = 'payment:voucher-codes'
    private readonly voucherCodes$$ = new BehaviorSubject<VoucherCodes>([])

    constructor(
        private readonly createPaymentMutationService: CreatePaymentMutationService,
        private readonly paymentQueryService: PaymentQueryService,
        private readonly verifyPaymentMutationService: VerifyPaymentMutationService,
        private readonly vouchersByEmailQueryService: VouchersByEmailQueryService,
        private readonly authService: AuthService,
        private readonly storage: Storage,
    ) {
    }

    public async initialize(): Promise<void> {
        this.voucherCodes$$.next(
            await this.getStoredVoucherCodes(),
        )
    }

    /**
     * Creates a new payment for the given order-ids and vouchers.
     */
    public async createPayment(
        orderIds: readonly string[],
        vouchers?: readonly VoucherUsageInput[],
    ): Promise<PaymentCreationResultFragment> {
        const fetchResult = await firstValueFrom(
            this.createPaymentMutationService.mutate({
                orderIds,
                vouchers,
            }),
        )

        if (fetchResult.errors) {
            throw new Error(fetchResult.errors?.[0]?.message)
        }

        if (isNil(fetchResult.data)) {
            throw new Error('Unexpected createPayment response: expected data but got Nil')
        }

        return fetchResult.data.createPayment
    }

    /**
     * Get a list of available vouchers for the authenticated user.
     */
    public getVouchers(): Observable<readonly VoucherFragment[]> {
        return this.vouchersByEmailQueryService.fetch({
            email: this.authService.getUser()!.email,
        }, {
            fetchPolicy: 'network-only',
        }).pipe(
            map((result) => result.data!.vouchers as VoucherFragment[]),
            catchError(() => of([])),
            tap((vouchers) => this.removeUnavailableVoucherCodes(vouchers)),
            shareReplayOne(),
        )
    }

    public async getPayment(id: string): Promise<PaymentDetailFragment> {
        const result = await firstValueFrom(this.paymentQueryService.fetch({ id }))
        return result.data.payment
    }

    public async verifyPayment(id: string): Promise<PaymentDetailFragment> {
        const result = await firstValueFrom(this.verifyPaymentMutationService.mutate({ id }))
        return result.data!.verifyPayment
    }

    // ------------------------------------------------------------------------------
    //      Voucher codes
    // ------------------------------------------------------------------------------

    public getSelectedVoucherCodes(): VoucherCodes {
        return this.voucherCodes$$.getValue()
    }

    public watchSelectedVoucherCodes(): Observable<VoucherCodes> {
        return this.voucherCodes$$.pipe(
            distinctUntilChangedEquals(),
        )
    }

    public async removeVoucherCode(codeToRemove: string): Promise<void> {
        await this.patchVoucherCodes((codes) => codes.filter((code) => code !== codeToRemove))
    }

    public async addVoucherCode(codeToAdd: string): Promise<void> {
        await this.patchVoucherCodes((codes) => uniq([...codes, codeToAdd]))
    }

    public async clearSelectedVoucherCodes(): Promise<void> {
        await this.patchVoucherCodes(() => [])
    }

    private async removeUnavailableVoucherCodes(vouchers: readonly VoucherFragment[]): Promise<void> {
        await this.patchVoucherCodes((codes) => codes.filter((code) => vouchers.some(propEq('voucherCode', code))))
    }

    private async patchVoucherCodes(fn: UnaryFunction<VoucherCodes>): Promise<VoucherCodes> {
        const prevCodes = this.getSelectedVoucherCodes()
        const nextCodes = fn(prevCodes)

        if (! equals(prevCodes, nextCodes)) {
            await this.storage.set(this.voucherCodesStorageKey, nextCodes)
            this.voucherCodes$$.next(nextCodes)
        }

        return nextCodes
    }

    private getStoredVoucherCodes(): Promise<VoucherCodes> {
        return this.storage
            .get(this.voucherCodesStorageKey)
            .then(defaultTo([]))
    }
}
