// noinspection JSUnusedGlobalSymbols
//
// ------------------------------------------------------------------------------
//      Generated GraphQL schema for Join Program Pubsub service
// ------------------------------------------------------------------------------
//      Stage        : Acceptance
//      Source       : https://v2.pubsub.acc.joinprogram.pwstaging.tech/graphql
//      Generated at : Wed Nov 27 11:00:14 UTC 2024
// ------------------------------------------------------------------------------

import { gql } from 'apollo-angular'
import { Injectable } from '@angular/core'
import * as Apollo from 'apollo-angular'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: { input: string; output: string }
    String: { input: string; output: string }
    Boolean: { input: boolean; output: boolean }
    Int: { input: number; output: number }
    Float: { input: number; output: number }
    ClientShortcode: { input: string; output: string }
    LocationShortcode: { input: string; output: string }
    MessageID: { input: string; output: string }
    Semver: { input: string; output: string }
}

export interface ApiUpdate {
    readonly messageID: Scalars['MessageID']['output']
    readonly version: Scalars['Semver']['output']
}

export interface Mutation {
    /** Submit an API update signal to all subscribers */
    readonly notifyAPIUpdate: ApiUpdate
    /** Submit an order status update to broadcast to all subscribers */
    readonly updateOrderStatus: Scalars['MessageID']['output']
    /**
   * Submit a new stock update to broadcast to subscribers.
   * Note: This is the new mutation that processes updates for the (soon to be introduced) MenuEntryOccurrence model.
   */
    readonly updateStock: ReadonlyArray<UpdateStockResult>
    /**
   * Submit a new stock update to broadcast to subscribers.
   * Note: This is the old mutation that processes updates for the (soon discontinued) MenuItemStock model.
   */
    readonly updateStockOld: Scalars['MessageID']['output']
}


export interface MutationNotifyApiUpdateArgs {
    version: Scalars['Semver']['input']
}


export interface MutationUpdateOrderStatusArgs {
    clientShortcode: Scalars['String']['input']
    locationShortcode: Scalars['String']['input']
    nextStatus: OrderStatusEnum
    orderID: Scalars['ID']['input']
    prevStatus?: InputMaybe<OrderStatusEnum>
    restaurantID: Scalars['String']['input']
}


export interface MutationUpdateStockArgs {
    amountClaimed: Scalars['Int']['input']
    amountIssued: Scalars['Int']['input']
    amountTotalCorrection: Scalars['Int']['input']
    amountTotalInitial?: InputMaybe<Scalars['Int']['input']>
    clientShortcodes: ReadonlyArray<Scalars['ClientShortcode']['input']>
    locationShortcode: Scalars['LocationShortcode']['input']
    menuEntryID: Scalars['ID']['input']
    menuEntryOccurrenceID: Scalars['ID']['input']
    restaurantID: Scalars['ID']['input']
}


export interface MutationUpdateStockOldArgs {
    clientShortcode: Scalars['String']['input']
    locationShortcode: Scalars['String']['input']
    menuItemID: Scalars['ID']['input']
    menuItemStockID: Scalars['ID']['input']
    restaurantID: Scalars['String']['input']
    stockClaimed: Scalars['Int']['input']
    stockIssued: Scalars['Int']['input']
    stockTotal: Scalars['Int']['input']
}

export enum OrderStatusEnum {
    Canceled = 'CANCELED',
    Failed = 'FAILED',
    Open = 'OPEN',
    Paid = 'PAID',
    PickedUp = 'PICKED_UP',
}

export interface OrderStatusUpdate {
    readonly messageID: Scalars['MessageID']['output']
    readonly nextStatus: OrderStatusEnum
    readonly orderID: Scalars['ID']['output']
    readonly prevStatus?: Maybe<OrderStatusEnum>
}

export enum OrderStatusUpdateScopeType {
    Client = 'CLIENT',
    Location = 'LOCATION',
    Order = 'ORDER',
    Restaurant = 'RESTAURANT',
}

export interface Query {
    readonly version: Scalars['Semver']['output']
}

export interface StockUpdate {
    readonly menuItemID: Scalars['ID']['output']
    readonly menuItemStockID: Scalars['ID']['output']
    readonly messageID: Scalars['MessageID']['output']
    readonly stockClaimed: Scalars['Int']['output']
    readonly stockIssued: Scalars['Int']['output']
    readonly stockTotal: Scalars['Int']['output']
}

export interface StockUpdateNew {
    /**
   * The amount of stock that is available for new orders and free issuing by kitchen staff.
   * This value will be null when no total constraint is actively enforced.
   */
    readonly amountAvailable?: Maybe<Scalars['Int']['output']>
    /**
   * (canonical) The amount of stock that has been claimed, i.e. (pre-)ordered. An item can be
   * claimed either by consumers through pre-orders in the app, or by kitchen staff who issue
   * free stock to ad-hoc consumers.
   */
    readonly amountClaimed: Scalars['Int']['output']
    /**
   * The portion of the amount total that has not yet been issued.
   * This value will be null when no total constraint is actively enforced.
   */
    readonly amountIssuable?: Maybe<Scalars['Int']['output']>
    /**
   * (canonical) The amount of stock that has been issued to consumers. All items that are issued must have
   * been claimed before, i.e. for each item issued, the amount claimed must have been incremented
   * by 1 previously, or at the time of issuing.
   */
    readonly amountIssued: Scalars['Int']['output']
    /** The amount of stock that has been claimed but not yet issued. */
    readonly amountReserved: Scalars['Int']['output']
    /**
   * This is the corrected total amount of stock that is available for an item.
   * This value will be null when no total constraint is actively enforced.
   */
    readonly amountTotal?: Maybe<Scalars['Int']['output']>
    /**
   * (canonical) The manual correction on the amountTotalInitial. This is the only number that
   * can be less than zero, implying a lower available stock than the (immutable) amountTotalInitial
   * implies. It can never be less than the negative mirror of amountTotalInitial,
   * e.g. if amountTotalInitial = 100, amountTotalCorrection >= -100.
   */
    readonly amountTotalCorrection: Scalars['Int']['output']
    /**
   * (canonical) This is the initial total amount of stock that is available for an item.
   * When his value is null it means that no total constraint is actively enforced.
   */
    readonly amountTotalInitial?: Maybe<Scalars['Int']['output']>
    /**
   * If true, the stock is actively constrained to a maximum and all nullable numbers in
   * this object will have an integer value. If false, all nullable numbers will be NULL.
   * Shortcut for `amountTotalInitial !== null`.
   */
    readonly isConstrained: Scalars['Boolean']['output']
    /**
   * Indicates if the total stock has been adjusted manually by kitchen staff, and therefore
   * the stockTotalInitial should be considered outdated. Adjustments can only have been made
   * on constrained stocks.
   * Shortcut for `amountTotalInitial !== null && stockTotalCorrection !== 0`.
   */
    readonly isCorrected: Scalars['Boolean']['output']
    /**
   * Indicates if the stock is fully used.
   * Shortcut for `amountTotalInitial !== null && amountAvailable === 0`.
   */
    readonly isExhausted: Scalars['Boolean']['output']
    readonly menuEntryID: Scalars['ID']['output']
    readonly menuEntryOccurrenceID: Scalars['ID']['output']
    readonly messageID: Scalars['MessageID']['output']
}

export interface StockUpdateSubscriptionFilter {
    readonly locationShortcodes?: InputMaybe<ReadonlyArray<Scalars['LocationShortcode']['input']>>
    readonly menuEntryIDs?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>
    readonly menuEntryOccurrenceIDs?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>
    readonly restaurantIDs?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>
}

export interface Subscription {
    /** Subscribe to API update events. Emits whenever a new API version has been deployed. */
    readonly APIUpdates: ApiUpdate
    /**
   * Subscribe to order status updates for a given scope.
   * Provide the scope-type and the main identifier of the scope to subscribe to.
   * For clients and locations this is their shortcode. For restaurants it's their ID.
   */
    readonly orderStatusUpdates: OrderStatusUpdate
    /**
   * Subscribe to stock updates for given filters.
   * Note: This is the new subscription that broadcasts updates for the (soon to be introduced) MenuEntryOccurrence model.
   */
    readonly stockUpdates: StockUpdateNew
    /**
   * Subscribe to stock updates for a given scope.
   * Provide the scope-type and the main identifier of the scope to subscribe to.
   * For clients and locations this is their shortcode. For restaurants, menu-items
   * and stocks, it's their ID.
   * Note: This is the old subscription that broadcasts updates for the (soon discontinued) MenuItemStock model.
   */
    readonly stockUpdatesOld: StockUpdate
}


export interface SubscriptionOrderStatusUpdatesArgs {
    clientID: Scalars['String']['input']
    scopeID: Scalars['String']['input']
    scopeType: OrderStatusUpdateScopeType
}


export interface SubscriptionStockUpdatesArgs {
    clientID: Scalars['String']['input']
    filter?: InputMaybe<StockUpdateSubscriptionFilter>
}


export interface SubscriptionStockUpdatesOldArgs {
    clientID: Scalars['String']['input']
    scopeID: Scalars['String']['input']
    scopeType: SubscriptionScopeType
}

export enum SubscriptionScopeType {
    Client = 'CLIENT',
    Location = 'LOCATION',
    MenuItem = 'MENU_ITEM',
    MenuItemStock = 'MENU_ITEM_STOCK',
    Restaurant = 'RESTAURANT',
}

export interface UpdateStockResult {
    readonly clientShortcode: Scalars['ClientShortcode']['output']
    readonly messageID: Scalars['MessageID']['output']
}

export type StockUpdatesSubscriptionVariables = Exact<{
    clientShortcode: Scalars['String']['input']
}>


export type StockUpdatesSubscription = { readonly stockUpdates: StockUpdateFragment }

export type StockUpdateFragment = { readonly messageID: string; readonly menuEntryOccurrenceID: string; readonly amountTotal?: number | null; readonly amountAvailable?: number | null; readonly isConstrained: boolean }

export const StockUpdateFragmentDoc = gql`
    fragment StockUpdate on StockUpdateNew {
  messageID
  menuEntryOccurrenceID
  amountTotal
  amountAvailable
  isConstrained
}
    `
export const StockUpdatesDocument = gql`
    subscription stockUpdates($clientShortcode: String!) {
  stockUpdates(clientID: $clientShortcode) {
    ...StockUpdate
  }
}
    ${StockUpdateFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class StockUpdatesSubscriptionService extends Apollo.Subscription<StockUpdatesSubscription, StockUpdatesSubscriptionVariables> {
    override document = StockUpdatesDocument
    override client = 'pubsub'
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}