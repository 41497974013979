import { Injectable } from '@angular/core'
import { firstValueFrom, Observable, of, timer } from 'rxjs'
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators'
import {
    OrderStrategyEnum,
    RestaurantFragment,
    RestaurantQueryService,
    RestaurantsQueryService,
    RestaurantTypeEnum,
    TimeslotFragment,
    TimeslotsQueryService,
} from '@app-graphql/api-schema'
import { AuthService } from '@app/services/auth/auth.service'
import { eqBy, isEmpty, isNil, maxBy, minBy, path, prop } from 'ramda'
import {
    AnyDate,
    ensureSerialised,
    formatDate,
    ISODayNumber,
    isoDayNumber,
    ISOWeekDay,
    now,
    parseDateTimeStrict,
} from '@lib/date-time/date-time.lib'
import { isFuture, sub as subtractDuration } from 'date-fns'
import { forceFreshFetch } from '@lib/apollo/apollo.lib'
import type { Nil } from '@app/types/common.types'
import { DishBackgroundImageVariants } from '@app/domains/ui/pipes/dish-background-image/dish-background-image.pipe'
import { cached } from '@app/decorators/method/cached.decorator'
import { isString } from '@lib/assertions/assertions.lib'
import { resolveOrderStrategyNote, resolveOrderStrategyValue } from '@lib/order-strategies/order-strategies.lib'

@Injectable({
    providedIn: 'root',
})
export class RestaurantsService {

    /**
     * Stream of arrays including all restaurants that are available to the authenticated user.
     */
    public readonly availableRestaurants$: Observable<readonly RestaurantFragment[]>

    /**
     * The enumeration of restaurant types.
     */
    public readonly types = RestaurantTypeEnum

    constructor(
        private readonly restaurantsQueryService: RestaurantsQueryService,
        private readonly restaurantQueryService: RestaurantQueryService,
        private readonly timeslotsQueryService: TimeslotsQueryService,
        private readonly authService: AuthService,
    ) {
        this.availableRestaurants$ = this.authService.user$.pipe(
            distinctUntilChanged(eqBy(path(['location', 'id']))),
            switchMap((user) => {
                return isNil(user) || isNil(user.location)
                    ? of([])
                    // Force a network request here since there is no information in the query input
                    // that distinguishes between different user settings over time. Otherwise, we will
                    // just get cached - and thus now outdated - results.
                    : this.getRestaurants({ perPage: 1000, forceNetwork: true })
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        )
    }

    public getRestaurants(
        {
            perPage = 10,
            page = 1,
            forceNetwork = false,
        },
    ): Observable<readonly RestaurantFragment[]> {
        return this.restaurantsQueryService.fetch({
            input: { first: perPage, page },
        }, {
            fetchPolicy: forceFreshFetch(forceNetwork),
        }).pipe(
            map((result) => result.data.restaurants.data),
        )
    }

    public async getRestaurantById(
        id: string,
        forceNetwork: boolean = false,
    ): Promise<RestaurantFragment> {
        const result = await firstValueFrom(
            this.restaurantQueryService.fetch(
                { id },
                { fetchPolicy: forceFreshFetch(forceNetwork) },
            ),
        )

        return result.data.restaurant
    }

    public getTimeslots(restaurantId: string, date: AnyDate): Observable<readonly TimeslotFragment[]> {
        return this.timeslotsQueryService.fetch({
            input: {
                restaurant: {
                    connect: restaurantId,
                },
                date: ensureSerialised(date, 'date'),
            },
        }).pipe(
            map((result) => result.data.timeslots),
        )
    }

    public anyHasPickupFlow(restaurants: readonly RestaurantFragment[]): boolean
    public anyHasPickupFlow(): Promise<boolean>
    public anyHasPickupFlow(restaurants?: readonly RestaurantFragment[]): boolean | Promise<boolean> {
        if (restaurants) {
            return restaurants.some((restaurant) => restaurant.isQrCodePickup)
        }

        return firstValueFrom(this.getRestaurants({ perPage: 100 }))
            .then((x) => x.some((restaurant) => restaurant.isQrCodePickup))
    }

    /**
     * Returns an observable that periodically emits an updated list of remaining timeslots, comparing
     * against the current time.
     */
    public watchRemainingTimeslots(
        restaurantId: string,
        transferDate: string,
        orderDeadlineMarginMinutes: number,
        refreshIntervalMS: number = 10000,
    ): Observable<TimeslotFragment[]> {
        return this.getTimeslots(restaurantId, transferDate).pipe(
            switchMap((timeslots) => timer(0, refreshIntervalMS).pipe(
                map(() => timeslots.filter(({ endsAt }) => {
                    try {
                        const endsAtDate = parseDateTimeStrict(endsAt)
                        return isFuture(
                            subtractDuration(endsAtDate, { minutes: orderDeadlineMarginMinutes }),
                        )
                    } catch (error: unknown) {
                        return false
                    }
                })),
            )),
        )
    }

    public getOfficeDays(restaurant: RestaurantFragment, numbersOnly: true): ISODayNumber[]
    public getOfficeDays(restaurant: RestaurantFragment): ISOWeekDay[]
    public getOfficeDays(restaurant: RestaurantFragment, numbersOnly = false): ISOWeekDay[] | ISODayNumber[] {
        return numbersOnly
            ? restaurant.officeDays.map(({ dayOfTheWeek }) => isoDayNumber(dayOfTheWeek))
            : restaurant.officeDays.map(({ dayOfTheWeek }) => ({
                weekDay: dayOfTheWeek,
                isoDayNumber: isoDayNumber(dayOfTheWeek),
            }))
    }

    /**
     * Returns a date instance representing the date/time at which an order can be reserved
     * for the given restaurant and transfer date. The returned promise resolves null if the
     * given restaurant does not take orders on the given transfer-date.
     */
    public async orderDeadline(restaurant: RestaurantFragment, transferDate: Date): Promise<Date | null> {
        const bounds = await this.openingTimes(restaurant, transferDate)

        if (isNil(bounds)) {
            return null
        }

        return subtractDuration(
            bounds[1],
            { minutes: restaurant.orderDeadlineMarginMinutes },
        )
    }

    public async openingTimes(
        restaurant: RestaurantFragment,
        transferDate: Date = now(),
    ): Promise<[Date, Date] | null> {
        if (restaurant.hasTimeslots) {
            const timeslots = await firstValueFrom(this.getTimeslots(restaurant.id, transferDate))

            if (isEmpty(timeslots)) {
                return null
            }

            const [earliestTimeslot, latestTimeslot] = [
                timeslots.reduce(minBy<TimeslotFragment>(prop('startsAt'))),
                timeslots.reduce(maxBy<TimeslotFragment>(prop('startsAt'))),
            ]

            return [
                parseDateTimeStrict(earliestTimeslot.startsAt),
                parseDateTimeStrict(latestTimeslot.endsAt),
            ]
        }

        const officeDay = restaurant.officeDays.find(({ dayOfTheWeek }) => (
            isoDayNumber(dayOfTheWeek) === transferDate.getDay()
        ))

        if (isNil(officeDay)) {
            return null
        }

        return [
            parseDateTimeStrict(`${formatDate(transferDate)} ${officeDay.openingTime}:00`),
            parseDateTimeStrict(`${formatDate(transferDate)} ${officeDay.closingTime}:00`),
        ]
    }

    @cached<[RestaurantFragment | string | Nil, DishBackgroundImageVariants]>({
        TTL: null,
        KEY: ([restaurant, variant]) => {
            const restaurantId = isNil(restaurant) ? 'Nil' : isString(restaurant) ? restaurant : restaurant.id
            return `${restaurantId}:${variant}`
        },
    })
    public async getRestaurantDishBackgroundImage(
        restaurant: RestaurantFragment | string | Nil,
        imageVariant: DishBackgroundImageVariants,
    ): Promise<string | null> {
        if (! restaurant || ! imageVariant) {
            return null
        }

        if (this.isRestaurantFragment(restaurant)) {
            return restaurant?.dishBackgroundImage?.[imageVariant] || null
        }

        try {
            const result = await this.getRestaurantById(restaurant)
            return result.dishBackgroundImage?.[imageVariant] || null
        } catch (err) {
            return null
        }
    }

    public resolveOrderStrategyValue(restaurant: RestaurantFragment): OrderStrategyEnum {
        return resolveOrderStrategyValue(restaurant, this.authService.getClientId()!)
    }

    public resolveOrderStrategyNote(restaurant: RestaurantFragment): string | null {
        return resolveOrderStrategyNote(restaurant, this.authService.getClientId()!)
    }

    private isRestaurantFragment(restaurant: RestaurantFragment | string): restaurant is RestaurantFragment {
        return typeof restaurant !== 'string'
    }
}
